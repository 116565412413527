import React, { useEffect, useState} from 'react';
import DashboardPage from './DashboardPage';
import PortfolioPage from './PortfolioPage';
import BuybackPage from './BuybackPage';
import { ethers } from "ethers";
import contract from '../../utils/InuCapital.json';

const DashboardContent = (props) => {
  const {address, sidebar, setSidebar, setOpen, accountData, disconnect, dashboardRef, portfolioRef, buybackRef} = props;
  const dashboardHeader = "text-3xl font-bold mt-8 mb-2 border-b-2 border-gray-600";
  const [ balance, setBalance ] = useState(0);
  const [ usdBalance, setUSDBalance ] = useState(0);
  const [ usdPrice, setUSDPrice ] = useState(0);
  const [ holders, setHolders ] = useState(0);
  const [ treasury, setTreasury ] = useState(0);
  const [ marketCap, setMarketCap ] = useState(0);
  const [ liquidity, setLiquidity ] = useState(0);
  const [ pendingDividend, setPendingDividend] = useState(0);
  const [ claimedDividend, setClaimedDividend] = useState(0);
  const [ pendingReflection, setPendingReflection] = useState(0);
  const [ claimedReflection, setClaimedReflection] = useState(0);


  // portfolio balance/usd, price
  useEffect(() => {
      const fetchData = async () => {
        const { ethereum } = window;
        if (ethereum) {
          const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner();
          const connectedContract = new ethers.Contract(process.env.REACT_APP_TOKEN_ADDRESS, contract.abi, signer);
          let divTxn = await connectedContract.getAccountDividendInfo(address);
          let pendingDividend = parseFloat(ethers.utils.formatEther(divTxn.at(1))).toFixed(5);
          let claimedDividend = parseFloat(ethers.utils.formatEther(divTxn.at(2))).toFixed(5) - pendingDividend;
          setPendingDividend(pendingDividend);
          setClaimedDividend(claimedDividend);

          let refTxn = await connectedContract.getAccountReflectionInfo(address);
          let pendingReflection = parseInt(ethers.utils.formatEther(refTxn.at(1)).split('.').at(0));
          let claimedReflection = parseInt(ethers.utils.formatEther(refTxn.at(2)).split('.').at(0)) - pendingReflection;
          setPendingReflection(pendingReflection);
          setClaimedReflection(claimedReflection);
        } else {
          console.log("Ethereum object doesn't exist!");
        }
      }

    if (address) {
      fetchData().catch(console.error);
    }
    if (address) {
      fetch(`https://api.covalenthq.com/v1/1/address/${address}/balances_v2/?key=ckey_67dd815cfb02407db132ea9be72`)
          .then(response => response.json())
          .then(data => {
            console.log('usdPrice', data)
            for(let i=0; i<data.data.items.length; i++) {
              if (data.data.items.at(i).contract_address === process.env.REACT_APP_TOKEN_ADDRESS.toLowerCase()) {
                setBalance((data.data.items.at(i).balance / (10 ** data.data.items.at(i).contract_decimals)).toFixed(0))
                setUSDBalance(data.data.items.at(i).quote.toFixed(2))
                setUSDPrice(data.data.items.at(i).quote_rate.toFixed(8))
                // setMarketCap(10000000000*data.data.items.at(i).quote_rate.toFixed(8))
                break;
              }
            }
          });
    }
  }, [address]);

  const claimDividend = async () => {
    const { ethereum } = window;
    if (ethereum) {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const connectedContract = new ethers.Contract(process.env.REACT_APP_TOKEN_ADDRESS, contract.abi, signer);
      let claimDividendTxn = await connectedContract.claimDividend();
      await claimDividendTxn.wait();
    } else {
      console.log("Ethereum object doesn't exist!");
    }
  }

  const claimReflection = async () => {
    const { ethereum } = window;
    if (ethereum) {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const connectedContract = new ethers.Contract(process.env.REACT_APP_TOKEN_ADDRESS, contract.abi, signer);
      let claimReflectionTxn = await connectedContract.claimReflection();
      await claimReflectionTxn.wait();
    } else {
      console.log("Ethereum object doesn't exist!");
    }
  }

  const claimBoth = async () => {
    const { ethereum } = window;
    if (ethereum) {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const connectedContract = new ethers.Contract(process.env.REACT_APP_TOKEN_ADDRESS, contract.abi, signer);
      let claimReflectionTxn = await connectedContract.claimAll();
      await claimReflectionTxn.wait();
    } else {
      console.log("Ethereum object doesn't exist!");
    }
  }
  // treasury AUM
  useEffect(() => {
    fetch(`https://api.covalenthq.com/v1/1/address/${process.env.REACT_APP_TREASURY_ADDRESS}/balances_v2/?key=ckey_67dd815cfb02407db132ea9be72`)
    // fetch(`https://api.covalenthq.com/v1/1/address/0xfE33e3E48b1BA04708037B9Da2F0D4caD7A42dfb/balances_v2/?key=ckey_67dd815cfb02407db132ea9be72`)
        .then(response => response.json())
        .then(data => {
          let total = 0;
          for(let i=0; i<data.data.items.length; i++) {
            total += data.data.items.at(i).quote
          }
          console.log('treasury AUM', total)
          setTreasury(total.toFixed(2));
        });
  }, []);

  //holders
  useEffect(() => {
    fetch(`https://api.covalenthq.com/v1/1/tokens/${process.env.REACT_APP_TOKEN_ADDRESS}/token_holders/?key=ckey_4ac5c350bd3f4c95b46dc7cbd9f&page-size=50000`)
        .then(response => response.json())
        .then(data => {
          setHolders(data.data.items.length);
        });
  }, []);

  // treasury liquidity manual computation from eth balance in LP address
  useEffect(() => {
    fetch(`https://api.covalenthq.com/v1/1/address/${process.env.REACT_APP_LP_ADDRESS}/balances_v2/?key=ckey_67dd815cfb02407db132ea9be72`)
        .then(response => response.json())
        .then(data => {
          let total = 0;
          for(let i=0; i<data.data.items.length; i++) {
            if (data.data.items.at(i).contract_ticker_symbol === "WETH") {
              total += data.data.items.at(i).quote
            }
            if (data.data.items.at(i).contract_address === process.env.REACT_APP_TOKEN_ADDRESS) {
              console.log(data.data.items.at(i).quote_rate);
              setUSDPrice(data.data.items.at(i).quote_rate);
            }
          }
          total = total * 2;
          console.log(data);
          setLiquidity(total.toFixed(2));
        });
  }, []);

  //  //uniswap liquidity
  //  useEffect(() => {
  //   fetch(`https://api.covalenthq.com/v1/1/address/${process.env.REACT_APP_LP_ADDRESS}/stacks/uniswap_v2/balances/?quote-currency=USD&format=JSON&key=ckey_docs`)
  //       .then(response => response.json())
  //       .then(data => {
  //         console.log(data);
  //         const balances = data.data.uniswap_v2.balances.at(0);
  //         let total = balances.token_0.quote + balances.token_1.quote;
  //         // setLiquidity(total);
  //       });
  // }, []);

  // market cap
  useEffect(() => {
    fetch(`https://api.coingecko.com/api/v3/coins/ethereum/contract/${process.env.REACT_APP_TOKEN_ADDRESS}/market_chart/?vs_currency=usd&days=1`)
        .then(response => response.json())
        .then(data => {
          console.log('MC', data)
          // setMarketCap((data.market_caps.at(0).at(0)/10000).toFixed(2));
        });
  }, []);

  return (
    <div className="bg-slate-400 flex flex-col max-h-screen flex-grow px-0  sm:px-24">
      <div class="flex flex-row items-center bg-white h-24 p-4 justify-between border-b-0">
        <div>
          <svg onClick={() => setSidebar(!sidebar)} xmlns="http://www.w3.org/2000/svg" class="sm:hidden block h-10 w-10 border-0 border-gray-600 p-1 bg-gray-500 rounded-lg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </div>
        {accountData
        ?
          <div className="flex ml-2 sm:ml-0 justify-center flex-row bg-gray-600 items-center space-x-0 sm:space-x-2 space-y-2 sm:space-y-0 px-4 py-2 rounded-lg">
            <div className="flex flex-col mr-4 text-slate-300">
              <div className="text-lg sm:text-xl">{accountData.address.substring(0,5)}...{accountData.address.substring(accountData.address.length - 3)}</div>
              <div className="">{accountData.connector.name}</div>
            </div>
            <button onClick={disconnect} className="transition ease-in-out hover:scale-110 duration-300 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border-0 rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700">
              Disconnect
            </button>
          </div>
        :
          <div className="flex ml-2 sm:ml-0 justify-center flex-row bg-gray-600 items-center space-x-0 sm:space-x-2 space-y-2 sm:space-y-0 px-4 py-2 rounded-lg">
            <div className="flex flex-col mr-4 text-slate-300">
              <div className="text-lg sm:text-xl">0x000...000</div>
              <div className="">No Wallet</div>
            </div>
            <button onClick={() => setOpen(true)} className="transition ease-in-out hover:scale-110 duration-300 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border-0 rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700">
              Connect
            </button>
          </div>
        }
      </div>
      <div className="flex flex-col px-4 pb-10 max-h-screen no-scrollbar overflow-y-auto">
        <p ref={dashboardRef} className={dashboardHeader}>General</p>
        <DashboardPage liquidity={liquidity} holders={holders} treasury={treasury} marketCap={marketCap} usdPrice={usdPrice}/>
        <p ref={portfolioRef} className={dashboardHeader}>Portfolio</p>
        <PortfolioPage setOpen={setOpen} address={address} usdBalance={usdBalance} balance={balance} claimedDividend={claimedDividend} pendingDividend={pendingDividend} claimedReflection={claimedReflection} pendingReflection={pendingReflection} claimDividend={claimDividend} claimReflection={claimReflection} claimBoth={claimBoth}/>
        <p ref={buybackRef} className={dashboardHeader}>Buybacks</p>
        <BuybackPage/>
      </div>
    </div>
  )
}

export default DashboardContent;